import { Divider, Grid, Stack, Typography } from "@mui/material"
import { useState } from "react";
import { useForm } from "react-hook-form";
import { sendResetPasswordMail } from "src/services/mailService";
import { resetPassword } from "src/services/userService";
import { FormInputText } from "./formElements/formInputText";
import SubmitButton from "./formElements/formSubmitButton";
import ResponseModule from "./shared/reponseNotification";

const ForgotPasswordForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    const setError = () => {
        setResponse({ message: 'Something went wrong', error: true });
    }

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={6} sx={{ border: '1px solid #80808033' }}>
                <Stack spacing={2} sx={{ p: 2 }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                        Forgot Password
                    </Typography>
                    <Divider sx={{ mb: 4 }} />

                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        setResponse({ message: '', error: true });

                        resetPassword(data.email).then((response) => {

                            if (response.data && response.data.length > 0) {
                                sendResetPasswordMail({
                                    email: data.email,
                                    token: response.data[0].token,
                                }).then((response: any) => {
                                    if (response.data && response.data.message) {
                                        setResponse({ message: response.data.message, error: false });
                                    } else {
                                        setError();
                                    }
                                    setLoading(false);
                                }).catch((error) => {
                                    setError();
                                });
                            } else {
                                setLoading(false);
                                setResponse({ message: 'Email not found', error: true });
                            }
                        }).catch((error) => {
                            setLoading(false);
                            setError();
                        });
                    })}>
                        <Grid container spacing={3} justifyContent='space-between'>
                            <Grid item xs={12} alignItems='center'>
                                <FormInputText
                                    label='Account Email'
                                    name='email'
                                    register={register}
                                    type='email'
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item container xs={12} justifyContent='center'>
                                <SubmitButton
                                    loading={loading}
                                > Send Reset Mail </SubmitButton>
                            </Grid>
                        </Grid>
                    </form>

                    {response.message && <ResponseModule
                        message={response.message}
                        error={response.error}
                    />}
                </Stack>
            </Grid>

        </Grid>
    )
}

export default ForgotPasswordForm