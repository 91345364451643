import { LoadingButton } from "@mui/lab"
import SaveIcon from '@mui/icons-material/Save';

interface SubmitButtonProps {
    loading: boolean;
    children: React.ReactNode;
}

const SubmitButton = ({ loading, children }: SubmitButtonProps) => {

    return <LoadingButton
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="outlined"
        color='success'
        type='submit'
        loading={loading}
    >
        {children}
    </LoadingButton>
}

export default SubmitButton;