import { Box, Button, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import UserRow from '../userRoleRow';
import { useEffect, useState } from 'react';
import { getAllRoles } from 'src/services/roleService';
import { getUserServices } from 'src/services/userService';
import { list } from 'src/services/services';
import AddUserServiceModal from '../modals/addServiceModal';

const UserPermissionModule = ({
    id, email
}: {
    id: string,
    email: string
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [allServices, setAllServices] = useState<any[]>([])
    const [services, setServices] = useState<any[]>([])
    const [roles, setRoles] = useState<any[]>([])
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        Promise.all([list(), getAllRoles()]).then(responses => {
            setLoading(false);
            setAllServices(responses[0]);
            setRoles(responses[1]);
        });
    }, []);

    const handleOpenModal = () => {
        if (!allServices.length) {
            // Filter services that are already added to the user
            setAllServices(services.filter((service: any) => !services.find((userService: any) => userService._id === service._id)));
            setOpenModal(true);
        } else {
            setOpenModal(true);
        }
    }


    return <>

        <Paper style={{ margin: '32px 16px 32px 16px', width: '100%' }}>
            <Box sx={{ py: 2, px: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Service Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((service) => (
                            <UserRow
                                name={service?.service_name}
                                userId={id as string}
                                serviceId={service?._id}
                                key={service._id}
                                selectedRoleId={service?.role[0]?._id}
                                roles={roles}
                            />
                        ))}
                    </TableBody>
                </Table>
                <Grid item xs={12} sx={{ textAlign: 'end', mt: 3 }}>
                    <Button variant="outlined" color='success' onClick={() => handleOpenModal()}>
                        Authorize
                    </Button>
                </Grid>

                {openModal && <AddUserServiceModal
                    services={allServices}
                    onClose={(newService) => {
                        if (newService) {
                            setServices([...services, newService])
                        }
                        setOpenModal(false)
                    }}
                    userId={id as string}
                    userEmail={email || ''}
                    roles={roles}
                />}
            </Box>
        </Paper>
    </>

}

export default UserPermissionModule;