import { Box, Container, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { list } from 'src/services/services';
import { useParams } from 'react-router-dom';
import { Role } from 'src/types/role';
import { getAllRoles, updateRolePermissions } from 'src/services/roleService';
import LoadingContainer from 'src/components/shared/loadingContainer';
import { getAllPermissions } from 'src/services/permissionService';
import { Service } from 'src/types/service';
import PermissionTable from 'src/components/permissionTable';
import { Permission } from 'src/types/permission';
import SubmitButton from 'src/components/formElements/formSubmitButton';
import ResponseModule from 'src/components/shared/reponseNotification';

const EditRole = () => {
    const {
        handleSubmit,
        formState: { errors },
    } = useForm();
    let { id } = useParams();
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState<Role>();
    const [tab, setTab] = useState(0);
    const [permissions, setPermissions] = useState<any[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    useEffect(() => {
        Promise.all([getAllRoles(), getAllPermissions(), list()]).then((response) => {
            setRole(response[0].find((role: Role) => role._id === id));
            setPermissions(response[1]);
            setServices(response[2]);

            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
    }, []);

    function a11yProps(index: number) {
        return {
            id: `service-tab-${index}`, 'aria-controls': `service-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setResponse({ message: '', error: false });
        setTab(newValue);
    };

    const addPermissionToRole = (permission: Permission) => {
        setRole((prev: any) => {
            return {
                ...prev,
                permissions: [...prev?.permissions as Permission[], permission]
            }
        })
    }

    const removePermissionFromRole = (permission: Permission) => {
        setRole((prev: any) => {
            return {
                ...prev,
                permissions: prev?.permissions?.filter((rolePermission: Permission) => rolePermission._id !== permission._id)
            }
        })
    }

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                {<LoadingContainer loading={Boolean(loading)}>
                    <Box sx={{ my: 4, py: 2, px: 4 }}>
                        <Typography variant={"h4"} component={"h1"} gutterBottom>
                            Update {role?.role_name} Role
                        </Typography>
                        <form onSubmit={handleSubmit(() => {
                            setLoading(true);
                            updateRolePermissions(role as Role).then(() => {
                                setResponse({
                                    message: 'Role permissions updated successfully',
                                    error: false,
                                });
                                setLoading(false);
                            }).catch((error) => {
                                setResponse({
                                    message: error.toString(),
                                    error: true,
                                });
                                setLoading(false);
                            })
                        })}>
                            <Grid container spacing={2}>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} my={3}>
                                    <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                        {services.map((service, index) => {
                                            return <Tab key={service._id} label={service.service_name} {...a11yProps(index)} />
                                        })}
                                    </Tabs>
                                </Box>

                                <PermissionTable
                                    permissions={
                                        permissions.filter((permission: Permission) => permission.service_id === services[tab]._id).filter(
                                            (permission: Permission) => role?.permissions?.find((rolePermission: Permission) => rolePermission._id === permission._id))
                                    }
                                    allPermissions={
                                        permissions.filter((permission: Permission) => permission.service_id === services[tab]._id).filter(
                                            (permission: Permission) => !role?.permissions?.find((rolePermission: Permission) => rolePermission._id === permission._id))
                                    }
                                    addPermission={(value: Permission) => addPermissionToRole(value)}
                                    removePermission={(value: Permission) => removePermissionFromRole(value)}
                                />

                                {response.message && !loading && (
                                    <Grid item xs={12}>
                                        <ResponseModule
                                            message={response.message}
                                            error={response.error}
                                        />
                                    </Grid>)}
                                <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                    <SubmitButton
                                        loading={loading}
                                    >
                                        Save Selected Service Changes
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </LoadingContainer>}
            </Paper>
        </Container>
    );
}

export default EditRole;