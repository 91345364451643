import { Avatar, Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserPicture } from "src/services/userService";
import { User } from "src/types/user";
import LoadingContainer from "./shared/loadingContainer";

interface UserInfoCardProps {
    user: User
}

const UserInfoCard = ({ user }: UserInfoCardProps) => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleImageUpload = (e: any) => {
        setLoading(true);
        updateUserPicture(user._id).then((res) => {
            if (res.data && res.data[0]) {
                axios({
                    method: "put",
                    url: res.data[0].user_avatar,
                    headers: {
                        "Content-Type": "image/jpeg",
                    },
                    data: e.target.files[0],
                }).then(() => {
                    window.location.reload();
                });
            }
        })
    }

    return <Grid container justifyContent='center'>
        <Grid item xs={12} md={6} sx={{ border: '1px solid #80808033', position: 'relative' }}>
            <Button sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1, m: 2 }} variant="outlined"
                color='success' onClick={() => navigate(-1)} >Go Back</Button>
            <Stack spacing={2} sx={{ p: 2 }}>
                <LoadingContainer loading={loading}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{
                            background: 'none !important',
                            border: 'none !important',
                            boxShadow: 'none !important',
                        }}
                    >
                        <Avatar alt={user.first_name} src={user.user_avatar + `?updated_at=${Math.random()}`} sx={[{ margin: 'auto', width: 112, height: 112, cursor: 'pointer' }, {
                            '&:hover': {
                                border: '2px solid black'
                            },
                        },]} />
                        <input
                            accept="image/jpeg, image/jpg"
                            type="file"
                            onChange={(e) => handleImageUpload(e)}
                            hidden
                        />
                    </Button>
                </LoadingContainer>
                <Grid container spacing={3} justifyContent='space-between'>
                    <Grid item xs={6} alignItems='center'>
                        <Typography variant='body1'
                            sx={{ fontWeight: 'bold' }}
                        >
                            First Name
                        </Typography>
                        <Typography variant='body1'>
                            {user.first_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'
                            sx={{ fontWeight: 'bold' }}
                        >
                            Last Name
                        </Typography>
                        <Typography variant='body1'>
                            {user.last_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='center'>
                        <Typography variant='body1'
                            sx={{ fontWeight: 'bold' }}
                        >
                            Email
                        </Typography>
                        <Typography variant='body1'>
                            {user.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'
                            sx={{ fontWeight: 'bold' }}
                        >
                            Organization
                        </Typography>
                        <Typography variant='body1'>
                            {user.organization?.organization_name}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
    </Grid>
}

export default UserInfoCard;