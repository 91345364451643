import ConfirmDialog from "../shared/confirmDialog";
import React from "react";
import { deleteUserRoleService } from "../../services/userService";
import { Typography } from "@mui/material";
import { UserService } from "src/types/service";

interface DeleteUserServiceProps {
    service: UserService,
    onClose: () => void
}
const DeleteUserService = ({ service, onClose }: DeleteUserServiceProps) => {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <>
            <ConfirmDialog
                title="Remove User Service Authorization"
                open={open}
                isLoading={isLoading}
                onClose={() => {
                    onClose()
                    setOpen(false)
                }
                }
                onConfirm={() => {
                    setIsLoading(true)
                    deleteUserRoleService(service.user_id, service.role_id, service.service_id).then((response) => {
                        if (response) {
                            window.location.href = '/users/' + service.user_id
                            setOpen(false)
                            setIsLoading(false)
                        }
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }}
            >
                <Typography>
                    You are about to remove user's service authorization from the system. Are you sure you want to proceed?
                </Typography>
            </ConfirmDialog>

        </>

    )
}

export default DeleteUserService