import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import React from "react";
import Drawer from '@mui/material/Drawer';
import { Link, Typography } from "@mui/material";
import { useAuth } from "qreal-auth-provider/src";
import { authKeys, checkAuth } from "src/enums/authKeys";

interface DrawerMenuProps {
    isDrawerOpen: boolean,
    handleDrawerClose: any
}

const menu = [
    {
        title: 'Users',
        icon: 'people',
        subMenu: [
            {
                title: 'List',
                icon: 'people',
                link: '/',
                authKey: authKeys.userList
            },
            {
                title: 'Create',
                icon: 'create',
                link: '/users/create',
                authKey: authKeys.userCreate
            },
        ]
    },
    {
        title: 'Services',
        icon: '',
        subMenu: [
            {
                title: 'List',
                icon: 'people',
                link: '/services/list',
                authKey: authKeys.serviceList
            },
            {
                title: 'Create',
                icon: 'create',
                link: '/services/create',
                authKey: authKeys.serviceCreate
            },
        ]
    },
    {
        title: 'Organizations',
        icon: '',
        subMenu: [
            {
                title: 'List',
                icon: 'people',
                link: '/organizations/list',
                authKey: authKeys.organizationList
            },
            {
                title: 'Create',
                icon: 'create',
                link: '/organizations/create',
                authKey: authKeys.organizationCreate
            },
        ]
    },
    {
        title: 'Roles',
        icon: '',
        subMenu: [
            {
                title: 'List',
                icon: 'people',
                link: '/roles/list',
                authKey: authKeys.roleList
            },
            {
                title: 'Create',
                icon: 'create',
                link: '/roles/create',
                authKey: authKeys.roleCreate
            },
        ]
    },
    {
        title: 'Logs',
        icon: '',
        subMenu: [
            {
                title: 'Search',
                icon: 'people',
                link: '/log/history',
                authKey: authKeys.logSearch
            },
        ]
    },
]


const DrawerMenu = (props: DrawerMenuProps) => {
    const { authList } = useAuth();

    return (
        <Drawer
            anchor="left"
            open={props.isDrawerOpen}
            onClose={props.handleDrawerClose}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ margin: 'auto', display: 'flex' }}
                    disabled
                >
                    <img src={"/images/qreal_logo.png"} alt={"QReal Identity management"} width={'auto'} height={60} />
                </IconButton>
                {
                    menu.map((item, index) => {
                        return (
                            <>
                                <Typography variant="h6" pl={2} >
                                    {item.title}
                                </Typography>
                                <Divider />
                                <List key={index}>
                                    {item.subMenu.map((subItem, index) => (
                                        checkAuth(authList, subItem.authKey) && (
                                            <Link color="inherit" underline="none" href={subItem.link}>
                                                <ListItem key={subItem.title + index} disablePadding >
                                                    <ListItemIcon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={subItem.title} sx={{ cursor: 'pointer' }} />
                                                </ListItem>
                                            </Link>
                                        )
                                    ))}
                                </List >
                                {index == menu.length - 1 && <Divider />
                                }
                            </>
                        )
                    })
                }
            </Box>
        </Drawer >
    );

}

export default DrawerMenu;