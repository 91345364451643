import { User } from "../../types/user";
import ConfirmDialog from "../shared/confirmDialog";
import React from "react";
import { deleteUser } from "../../services/userService";
import { Typography } from "@mui/material";

interface DeleteUserModalProps {
    user: User,
    onClose: () => void,
    callback?: () => void
}
const DeleteUserModal = ({ user, onClose, callback }: DeleteUserModalProps) => {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <>
            <ConfirmDialog
                title="Delete User"
                open={open}
                isLoading={isLoading}
                onClose={() => {
                    onClose()
                    setOpen(false)
                }
                }
                onConfirm={() => {
                    setIsLoading(true)
                    deleteUser(user._id).then((response) => {
                        if (response) {
                            onClose()
                            setIsLoading(false)
                            if (callback) { callback() }
                        }
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }}
            >
                <Typography>
                    You are about to delete ({user.first_name} {user.last_name}) from the system. Are you sure you want to proceed?
                </Typography>
            </ConfirmDialog>

        </>

    )
}

export default DeleteUserModal