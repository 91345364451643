import { Box, Container, Divider, Grid } from "@mui/material"
import React from "react";

const PublicView = ({ children }: { children: React.ReactNode; }) => {

    return <Container maxWidth={"lg"}>
        <Grid container spacing={3} justifyContent='center'>
            <Grid item>
                <img src={"/images/qreal_logo.png"} alt={"QReal Identity management"} width={'auto'} height={60} />
            </Grid>
        </Grid>
        <Box sx={{ mb: 4, py: 2, px: 4 }}>
            <Divider sx={{ mb: 4 }} />
        </Box>

        {children}

    </Container>

}

export default PublicView;