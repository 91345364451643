export const authKeys = {
    identityRoot: "IDENTITY_ROOT",
    userCreate: "IDENTITY_USER_CREATE",
    serviceCreate: "IDENTITY_SERVICE_CREATE",
    serviceList: "IDENTITY_SERVICE_LIST",
    roleList: "IDENTITY_ROLE_LIST",
    roleCreate: "IDENTITY_ROLE_CREATE",
    userList: "IDENTITY_USER_LIST",
    userDelete: "IDENTITY_USER_DELETE",
    userUpdate: "IDENTITY_USER_UPDATE",
    serviceUpdate: "IDENTITY_SERVICE_UPDATE",
    roleUpdate: "IDENTITY_ROLE_UPDATE",
    organizationCreate: "IDENTITY_ORGANIZATION_CREATE",
    organizationList: "IDENTITY_ORGANIZATION_LIST",
    organizationDelete: "IDENTITY_ORGANIZATION_DELETE",
    organizationUpdate: "IDENTITY_ORGANIZATION_UPDATE",
    logSearch: "IDENTITY_LOG_SEARCH",
}

export const ROLE_KEYS = {
    ADMIN: "ADMIN",
    CUSTOMER: "CUSTOMER",
    READ_ONLY: "READ_ONLY",
}

export const checkAuth = (authList: Array<string>, key: string) => {
    return true
    if (authList.length > 0 && authList.includes(key)) {
        return true;
    }
    return false;
}
