import { Autocomplete, Box, Button, Chip, Container, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { list, updateService } from 'src/services/services';
import { Service } from 'src/types/service';
import { useParams } from 'react-router-dom';
const EditService = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [flag, setFlag] = useState(true);
    const [isShrink, setIsShrink] = useState(false);
    const [service, setService] = useState<Service>();
    const [region, setRegion] = useState<string>();
    const [serviceLog, setServiceLog] = useState<string>();
    const [serviceLogChipGroup, setServiceLogChipGroup] = useState<{ group: string; region: string; }[]>(
        [],
    );
    const [data, setData] = useState<string[]>([]);
    const regions: Array<string> = [
        'us-east-1',
        'us-east-2',
        'us-west-1',
        'us-west-2',
        'ap-south-1',
        'ap-northeast-1',
        'ap-northeast-2',
        'ap-northeast-3',
        'ap-southeast-1',
        'ap-southeast-2',
        'ca-central-1',
        'eu-central-1',
        'eu-west-1',
        'eu-west-2',
        'eu-west-3',
        'eu-north-3',
        'sa-east-1',
    ]

    useEffect(() => {
        list().then((response) => {
            const selectedService = response.find((service: Service) => service._id === id)
            setService(selectedService)
        });

    }, []);

    useEffect(() => {
        service?.service_log_groups.forEach((element: { group: string, region: string }) => {
            let group: string;
            group = element.region + '|' + element.group;

            if (flag) {
                setServiceLogChipGroup(prevState => [
                    ...prevState,
                    { region: element.region, group: element.group },
                ])
                setData(prevState => {
                    const clone = [...prevState];
                    clone.push(element.region + '|' + element.group)
                    return clone;
                });
                setFlag(false)
            }

        });

    }, [service])

    const handleCreateLogGroups = () => {
        if (region && serviceLog) {
            setServiceLogChipGroup(prevState => [
                ...prevState,
                { region: region, group: serviceLog },
            ])

            setData(prevState => {
                const clone = [...prevState];
                clone.push(region + '|' + serviceLog)
                return clone;
            });

            setServiceLog("")
            setIsShrink(false)
            setRegion('')

        }

    }

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                {service ? <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Update Service
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        data.serviceLogGroups = serviceLogChipGroup;
                        // console.log(data)
                        updateService({
                            _id: service._id,
                            service_status: data.serviceStatus,
                            service_description: data.serviceDescription,
                            service_domain: data.serviceDomain,
                            service_log_groups: data.serviceLogGroups,
                            // reciever props
                        }).then((response) => {
                            setLoading(false);
                        }).catch((error) => {
                            console.log(error);
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                                <FormInputText
                                    name="serviceName"
                                    register={register}
                                    label="Service name"
                                    defaultValue={service.service_name}
                                    disabled={true}
                                    errors={errors}
                                    optional={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="serviceDescription" register={register} label="Service description" errors={errors}
                                    defaultValue={service.service_description}
                                    helperText={errors.serviceName ? 'Please enter description for service.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="serviceDomain" register={register} label="Service domain" errors={errors} defaultValue={service.service_domain}
                                    helperText={errors.serviceDomain ? 'Please enter service domain.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputDropdown control={control} options={[
                                    { label: 'Active', value: 'ACTIVE' },
                                    { label: 'Passive', value: 'PASSIVE' }
                                ]}
                                    name="serviceStatus"
                                    label="Service status"
                                    defaultValue={service.service_status}
                                />

                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={[]}
                                    // defaultValue={data}
                                    value={data}
                                    freeSolo
                                    // disabled
                                    onChange={(e, value, situation, option) => {
                                        if (situation === "removeOption") {
                                            if (option?.option) {
                                                const logGroup = option.option.split('|')
                                                data.splice(data.indexOf(option?.option), 1)
                                                setData(data)
                                                setServiceLogChipGroup(serviceLogChipGroup.filter(item => !(item.region === logGroup[0] && item.group === logGroup[1])));
                                            }

                                        } else if (situation === "clear") {
                                            console.log('clear')
                                            setData([])
                                            setServiceLogChipGroup([])
                                        }

                                    }}
                                    renderTags={(
                                        value: any[],
                                        getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                                    ) =>

                                        value.map((option: any, index: any) => {
                                            return (
                                                <Chip
                                                    key={option}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label="Service Log Groups"
                                            placeholder="Select region and write service log, then click add"
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12} md={4}>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    getOptionLabel={(option) => option}
                                    noOptionsText=""
                                    onChange={(e, selected) => { setRegion(selected) }}
                                    value={region}
                                    disableClearable={true}
                                    key={region}
                                    renderInput={(params) => <TextField label="Region" variant="outlined" {...params} />}
                                    options={regions}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Service Log"
                                    fullWidth
                                    variant="outlined"
                                    value={serviceLog}
                                    InputLabelProps={{ shrink: isShrink }}
                                    onFocus={() => setIsShrink(true)}
                                    onChange={(e) => { setServiceLog(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='primary'
                                    loading={loading}
                                    onClick={(e) => handleCreateLogGroups()}
                                >
                                    Add
                                </LoadingButton>
                            </Grid>

                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='success'
                                    type='submit'
                                    loading={loading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box> : <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <LinearProgress />
                </Box>}
            </Paper>
        </Container>
    );
}

export default EditService;