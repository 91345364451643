import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TuneIcon from '@mui/icons-material/Tune';
import { Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/system";
import { useAuth } from 'qreal-auth-provider/src';
import React from 'react';
import UserRightClickMenu from 'src/components/rightClickMenu/userRightClickMenu';
import SearchFilter from 'src/components/searchFilter';
import SearchInput from 'src/components/searchInput';
import LoadingContainer from 'src/components/shared/loadingContainer';
import { authKeys, checkAuth } from 'src/enums/authKeys';
import useContextMenu from 'src/hooks/rightClickHook';
import DeleteUserModal from "../../components/modals/deleteUserModal";
import { listWithPagination, searchUsers } from "../../services/userService";
import { User } from "../../types/user";

const Users = () => {
    const INITIAL_PAGE = 1
    const { clicked, setClicked, points, setPoints } = useContextMenu();
    const [users, setUsers] = React.useState<User[]>([]);
    const [pageData, setPageData] = React.useState<any>({
        page: INITIAL_PAGE,
        per_page: 10,
        total: 0,
    });
    const [filterOptions, setFilterOptions] = React.useState<any>({
        search: '',
        organization: '',
        status: 'ACTIVE'
    });

    const { authList } = useAuth();

    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);

    const getList = () => {
        setLoading(true)
        listWithPagination(
            pageData.page,
            pageData.per_page
        ).then((response) => {
            setUsers(response.data)
            setLoading(false)
            setPageData({
                ...pageData,
                total: response.total_count
            })
        });
    }

    const getSearchedList = () => {
        setLoading(true)
        searchUsers(pageData.page, pageData.per_page, filterOptions).then((response) => {
            setLoading(false)
            setUsers(response.data)
            setPageData({
                ...pageData,
                total: response.total_count
            })

        }).catch((error) => {
            console.log('search error', error)
        });
    }

    React.useEffect(() => {
        getSearchedList();
    }, [pageData.page, filterOptions])

    return (
        <>
            <Container maxWidth={"lg"}>

                <Box sx={{ my: 4 }}>
                    <Grid container justifyContent={'space-between'} mb={3}>
                        <Grid item>
                            <Typography variant={"h4"} component={"h1"} gutterBottom>
                                Users
                            </Typography>
                        </Grid>
                        <Grid item>
                            <SearchFilter handleSubmit={(data) => {
                                setFilterOptions(data);
                                setPageData({
                                    ...pageData,
                                    page: INITIAL_PAGE
                                })
                            }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={3}>

                                <TableContainer component={Paper}>
                                    <LoadingContainer loading={loading}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">Surname</TableCell>
                                                    <TableCell align="right">Email</TableCell>
                                                    <TableCell align="right">Organization</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.map((user) => (
                                                    <TableRow
                                                        key={user._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        onContextMenu={(event) => {
                                                            event.preventDefault();
                                                            setClicked(true);
                                                            setSelectedRow(user);
                                                            setPoints({
                                                                x: event.pageX,
                                                                y: event.pageY,
                                                            });
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {user.first_name}
                                                        </TableCell>
                                                        <TableCell align="right">{user.last_name}</TableCell>
                                                        <TableCell align="right">{user.email}</TableCell>
                                                        <TableCell align="right">{user.organization?.organization_name}</TableCell>
                                                        <TableCell align="right">

                                                            {checkAuth(authList, authKeys.userUpdate) && <IconButton
                                                                aria-label="edit"
                                                                size="large"
                                                                onClick={() => window.open(`/users/${user._id}`, '_blank')}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>}

                                                            {checkAuth(authList, authKeys.userDelete) && <IconButton
                                                                aria-label="delete"
                                                                size="large"
                                                                onClick={() => setSelectedUser(user)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>


                                        </Table>
                                    </LoadingContainer>
                                </TableContainer>

                                {clicked && <UserRightClickMenu selectedRow={selectedRow} points={points} setClicked={setClicked} />}

                                {pageData.total > pageData.per_page &&
                                    <Grid container spacing={2} justifyContent="end" alignItems="end" mt={1}>
                                        <Grid item>
                                            <Pagination
                                                page={pageData.page}
                                                count={Math.ceil(pageData.total / pageData.per_page)}
                                                color="primary"
                                                onChange={
                                                    (event, value) => {
                                                        setPageData({
                                                            ...pageData,
                                                            page: value
                                                        })
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                }

                            </Paper>
                        </Grid>
                    </Grid>
                </Box>

            </Container>
            {selectedUser && <DeleteUserModal callback={() => getList()} user={selectedUser} onClose={() => setSelectedUser(null)} />}
        </>
    )
}

export default Users