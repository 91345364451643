import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/system";
import { Grid, Pagination } from "@mui/material";
import { Paper } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { listWithPagination } from '../../services/services';
import { Service } from 'src/types/service';
import DeleteServiceModal from 'src/components/modals/deleteServiceModal';
import { useAuth } from 'qreal-auth-provider/src';
import { authKeys, checkAuth } from 'src/enums/authKeys';

const Services = () => {
    const [services, setServices] = React.useState<Service[]>([]);
    const [selectedService, setSelectedService] = React.useState<Service | null>(null);
    const { authList } = useAuth();
    const [pageData, setPageData] = React.useState<any>({
        page: 1,
        per_page: 10,
        total: 0,
    });

    const getList = () => {
        listWithPagination(
            pageData.page,
            pageData.per_page
        ).then((response) => {
            setServices(response.data)
            setPageData({
                ...pageData,
                total: response.total_count
            })
        });
    }

    React.useEffect(() => {
        getList();
    }, []);

    React.useEffect(() => {
        getList();
    }, [pageData.page])

    return (
        <>
            <Container maxWidth={"lg"}>
                <Box sx={{ my: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Services
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={3}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Status</TableCell>
                                                <TableCell align="right">Domain</TableCell>
                                                <TableCell align="right">Created At</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {services.map((service) => (
                                                <TableRow
                                                    key={service._id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {service.service_name}
                                                    </TableCell>
                                                    <TableCell align="right">{service.service_status}</TableCell>
                                                    <TableCell align="right">{service.service_domain}</TableCell>
                                                    <TableCell align="right">{service.created_at}</TableCell>
                                                    <TableCell align="right">
                                                        {checkAuth(authList, authKeys.serviceUpdate) && <IconButton
                                                            aria-label="edit"
                                                            size="large"
                                                            onClick={() => window.location.href = `/services/${service._id}`}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>}
                                                        {/* <IconButton
                                                            aria-label="delete"
                                                            size="large"
                                                            onClick={() => setSelectedService(service)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            {pageData.total > pageData.per_page && <Grid container spacing={2} justifyContent="end" alignItems="end" mt={1}>
                                <Grid item>
                                    <Pagination count={Math.ceil(pageData.total / pageData.per_page)} color="primary" onChange={
                                        (event, value) => {
                                            setPageData({
                                                ...pageData,
                                                page: value
                                            })
                                        }
                                    } />
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            {selectedService && <DeleteServiceModal service={selectedService} onClose={() => setSelectedService(null)} />}
        </>
    )
}

export default Services