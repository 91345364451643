import { Button, FormControl, LinearProgress, MenuItem, Select, TableCell, TableRow } from "@mui/material"
import { useState } from "react"
import { changeUserRoleService } from "src/services/userService"
import DeleteUserService from "./modals/deleteUserService"

interface UserRowProps {
    name: string
    userId: string
    serviceId: string
    roles: any[]
    selectedRoleId: string
}

const UserRow = ({ userId, roles, selectedRoleId, name, serviceId }: UserRowProps) => {
    const [selectedRole, setSelectedRole] = useState(selectedRoleId)
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const handleRoleChange = (event: any) => {
        setLoading(true);
        setSelectedRole(event.target.value);
        changeUserRoleService(userId, event.target.value, serviceId).then(() => {
            setLoading(false);
        })
    }

    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            {loading ? <TableCell><LinearProgress /></TableCell> : <TableCell>
                <FormControl size="small">
                    <Select value={selectedRole} onChange={(e) => handleRoleChange(e)}>
                        {roles.map((role) => (
                            <MenuItem value={role._id}>
                                {role.role_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </TableCell>}
            <TableCell>
                <Button variant="outlined" color='error' onClick={() => setOpenModal(true)}>
                    Remove
                </Button>
            </TableCell>
           {openModal &&  <DeleteUserService onClose={() => setOpenModal(false)} service={{
                user_id: userId,
                service_id: serviceId,
                role_id: selectedRole
            }} />}
        </TableRow>
    )
}

export default UserRow