import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { createService } from 'src/services/services';
import ResponseModule from 'src/components/shared/reponseNotification';
import { createRole } from 'src/services/roleService';

const CreateRole = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Create Role
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        createRole({
                            role_name: data.roleName,
                            role_description: data.roleDescription
                        }).then((response) => {
                            setResponse({
                                message: 'Role created successfully',
                                error: false,
                            });
                            setLoading(false);
                        }).catch((error) => {
                            setResponse({
                                message: error.toString(),
                                error: true,
                            });
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                                <FormInputText
                                    name="roleName"
                                    register={register}
                                    label="Role name"
                                    errors={errors}
                                    helperText={errors.roleName ? 'Please enter role name.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="roleDescription" register={register} label="Role description" errors={errors}
                                    helperText={errors.roleName ? 'Please enter description for role.' : ''}
                                />
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={12}>
                                    <ResponseModule
                                        message={response.message}
                                        error={response.error}
                                    />
                                </Grid>)}
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='success'
                                    type='submit'
                                >
                                    Create
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

export default CreateRole;