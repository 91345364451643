import { post } from "./helpers/axiosHelper";

const MAIL_SERVICE_URL = process.env.REACT_APP_MAIL_SERVICE;

export const sendWelcomeMail = async (args: { email: string; name: string; password: string }) => {
    try {
        return await post({
            url: MAIL_SERVICE_URL + '/notification/emails',
            body: {
                type: "MAIL_NOTIFICATION",
                consumer: "MailNotificationConsumer",
                data: {
                    template: "WELCOME",
                    receiver: args.email,
                    subject: "Welcome to QReal 🥳",
                    mail_data: {
                        email: args.email,
                        name: args.name,
                        password: args.password
                    }
                }
            },
            config: {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        })

    } catch (error: any) {
        throw new Error(error);
    }
}

export const sendAccessNotification = async (args: { email: string; domain: string; service: string }) => {
    try {
        console.log('test', args);
        return await post({
            url: MAIL_SERVICE_URL + '/notification/emails',
            body: {
                type: "MAIL_NOTIFICATION",
                consumer: "MailNotificationConsumer",
                data: {
                    template: "ACCESS_NOTIFICATION",
                    receiver: args.email,
                    subject: `Access Granted QReal - ${args.service}`,
                    mail_data: {
                        email: args.email,
                        domain: args.domain,
                        service: args.service
                    }
                }
            },
            config: {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        })
    } catch (error: any) {
        throw new Error(error);
    }
}

export const sendResetPasswordMail = async (args: { token: string, email: string }) => {
    try {
        return await post({
            url: MAIL_SERVICE_URL + '/notification/emails',
            body: {
                type: "MAIL_NOTIFICATION",
                consumer: "MailNotificationConsumer",
                data: {
                    template: "RESET_PASSWORD",
                    receiver: args.email,
                    subject: "Forgot Password 🐟",
                    mail_data: {
                        token: args.token
                    }
                }
            },
            config: {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        })
    } catch (error: any) {
        throw new Error(error);
    }
}