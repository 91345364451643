import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { createService } from 'src/services/services';
import ResponseModule from 'src/components/shared/reponseNotification';

const CreateService = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Create Service
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        createService({
                            service_name: data.serviceName,
                            service_description: data.serviceDescription,
                            service_domain: data.serviceDomain,
                        }).then((response) => {
                            setResponse({
                                message: 'Service created successfully',
                                error: false,
                            });
                            setLoading(false);
                        }).catch((error) => {
                            setResponse({
                                message: error.toString(),
                                error: true,
                            });
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                                <FormInputText
                                    name="serviceName"
                                    register={register}
                                    label="Service name"
                                    errors={errors}
                                    helperText={errors.serviceName ? 'Please enter service name.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="serviceDomain" register={register} label="Service domain" errors={errors}
                                    helperText={errors.serviceDomain ? 'Please enter service domain.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="serviceDescription" register={register} label="Service description" errors={errors}
                                    helperText={errors.serviceName ? 'Please enter description for service.' : ''}
                                />
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={12}>
                                <ResponseModule 
                                    message={response.message}
                                    error={response.error}
                                />
                            </Grid>)}
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='success'
                                    type='submit'
                                >
                                    Create
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

export default CreateService;