

import React from 'react';
import { FormControl, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';

interface FormInputTextProps {
    name: string;
    register: any;
    label: string;
    type?: 'text' | 'email' | 'password';
    optional?: boolean;
    helperText?: string;
    defaultValue?: string;
    mask?: string;
    control?: any;
    size?: string;
    disabled?: boolean;
    errors: any;
}

export const FormInputText = ({ size = '', control, mask, name, type = 'text', register, label, disabled = false, optional = false, errors, helperText, defaultValue }: FormInputTextProps) => {
    return (
        <>
            {mask ?
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={name}
                    render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth>
                            <InputMask
                                mask={mask}
                                disabled={false}
                                onChange={onChange}
                            >
                                <TextField type={type} disabled={disabled} defaultValue={defaultValue} fullWidth label={label} helperText={errors[name] ? helperText : ''} error={errors[name] ? true : false} />
                            </InputMask>
                        </FormControl>
                    )}
                />
                :
                <TextField size={size} type={type} disabled={disabled} defaultValue={defaultValue} fullWidth label={label} {...register(name, { required: !optional })} helperText={errors[name] ? helperText : ''} error={errors[name] ? true : false} />
            }
        </>
    );
};