import { Box, Button, Container, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAllOrganizations, updateOrganization } from 'src/services/organizationService';
import { Organization } from 'src/types/organization';
import ResponseModule from 'src/components/shared/reponseNotification';
import ManageLicence from 'src/components/organization/ManageLicence';

const EditOrganization = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization>();
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    useEffect(() => {
        getAllOrganizations().then((response) => {
            setOrganization(response.find((organization: Organization) => organization._id === id))
            setLoading(false);
        });
    }, []);

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                {organization ? <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Update Organization
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        updateOrganization({
                            _id: organization._id,
                            organization_status: data.organizationStatus,
                            organization_description: data.organizationDescription,
                        }).then((response) => {
                            setResponse({
                                message: 'Organization updated successfully',
                                error: false,
                            });
                            setLoading(false);
                        }).catch((error) => {
                            setResponse({
                                message: error.toString(),
                                error: true,
                            });
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                                <FormInputText
                                    name="organizationName"
                                    register={register}
                                    label="Organization name"
                                    defaultValue={organization?.organization_name}
                                    disabled={true}
                                    errors={errors}
                                    optional={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText name="organizationDescription" register={register} label="Organization description" errors={errors}
                                    defaultValue={organization?.organization_description}
                                    helperText={errors.organizationName ? 'Please enter description for organization.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputDropdown control={control} options={[
                                    { label: 'Active', value: 'ACTIVE' },
                                    { label: 'Passive', value: 'PASSIVE' }
                                ]}
                                    name="organizationStatus"
                                    label="Organization status"
                                    defaultValue={organization?.organization_status}
                                />

                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='success'
                                    type='submit'
                                    loading={loading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={12}>
                                    <ResponseModule
                                        message={response.message}
                                        error={response.error}
                                    />
                                </Grid>)}
                        </Grid>
                    </form>
                </Box> : <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <LinearProgress />
                </Box>}
            </Paper>

            <Paper>
                <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h5"} component={"h1"} gutterBottom>
                        Licenses of Organization
                    </Typography>

                    {id && <ManageLicence id={id} />}
                </Box>
            </Paper>
        </Container>
    );
}

export default EditOrganization;