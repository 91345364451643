import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Controller } from "react-hook-form";

interface FormDatePickerProps {
    name: string;
    register: any;
    label: string;
    type?: 'text' | 'email' | 'password';
    optional?: boolean;
    helperText?: string;
    defaultValue?: any;
    size?: string;
    disabled?: boolean;
    errors: any;
    control: any;

}

export const FormDatePicker = ({ size = '', control, name, type = 'text', register, label, disabled = false, optional = false, errors, helperText, defaultValue }: FormDatePickerProps) => {
    const date = new Date(defaultValue);

    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{ width: '100%' }}
                        label={label || "BirthDate"}
                        defaultValue={dayjs(date) || null}
                        onChange={(event) => { onChange(event); }}
                    />
                </LocalizationProvider>
            )}
        />

    );
};