import { Button, Chip, Grid, Hidden, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import LoadingContainer from "../shared/loadingContainer"
import { getOrganizationLicenses } from "src/services/organizationService"
import { License } from "src/types/organization"
import CopyButton from "../shared/copyText"
import moment from "moment";
import { set, useForm } from "react-hook-form"
import { FormInputText } from "../formElements/formInputText"
import { FormDatePicker } from "../formElements/formDatePicker"
import SubmitButton from "../formElements/formSubmitButton"
import { createLicense, deleteLicense, updateLicense } from "src/services/licenceService"
import { createToken } from "src/functions/hash"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import ConfirmDialog from "../shared/confirmDialog"


const LicenceForm = ({ organization_id, callback }: {
    organization_id: string,
    callback: () => void
}) => {
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    return (
        <form onSubmit={handleSubmit((data) => {
            createToken().then((token) => {
                setLoading(true);
                createLicense({
                    organization_id: organization_id,
                    license_key: token,
                    bundle_identifier: data.licenceIdentifier,
                    expire_date: data.expireDate,
                }).then((response) => {
                    if (response.data?.is_completed) {
                        callback()
                    }
                    setLoading(false);
                }).catch((error) => {
                    setLoading(false);
                })
            })

        })}>
            <Grid container spacing={2} mb={3} alignItems={'center'}>
                <Grid item xs={12} md={5}>
                    <FormInputText
                        name="licenceIdentifier"
                        register={register}
                        label="Licence Identifier"
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <FormDatePicker
                        name="expireDate"
                        register={register}
                        label="Expire Date"
                        defaultValue={new Date()}
                        control={control}
                        errors={errors}
                        helperText={'Please enter expire date.'}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SubmitButton loading={loading}>
                        Create
                    </SubmitButton>
                </Grid>
            </Grid>
        </form>
    )
}

const LicenceTable = ({ licenses, callback }: {
    licenses: License[],
    callback: () => void
}) => {
    const [selectedUpdateLicense, setSelectedUpdateLicense] = useState<License | null>(null)
    const [selectedDeleteLicense, setSelectedDeleteLicense] = useState<License | null>(null)
    const [loading, setLoading] = useState(false)

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}> <Typography variant="subtitle1">Key</Typography> </Grid>
            <Grid item xs={3}> <Typography variant="subtitle1">Identifier</Typography> </Grid>
            <Hidden mdDown>
                <Grid item xs={3}> <Typography variant="subtitle1">Expire Date</Typography> </Grid>
            </Hidden>
            {licenses.map((license: License) => {
                return (
                    <Grid item xs={12} key={license.license_key}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={6} md={3}>
                                <CopyButton text={license.license_key} />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                {selectedUpdateLicense && selectedUpdateLicense.license_key === license.license_key ?
                                    <>
                                        <TextField label='Identifier' defaultValue={license.bundle_identifier} size="small" onChange={(e) => {
                                            setSelectedUpdateLicense({ ...selectedUpdateLicense, bundle_identifier: e.target.value })
                                        }} />
                                    </> : <Chip label={license.bundle_identifier} />}
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={6} md={3}>
                                    {selectedUpdateLicense && selectedUpdateLicense.license_key === license.license_key ?
                                        <>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    sx={{ width: '100%' }}
                                                    label="ExpireDate"
                                                    defaultValue={dayjs(new Date(license.expire_date)) || null}
                                                    onChange={(event) => { setSelectedUpdateLicense({ ...selectedUpdateLicense, expire_date: event?.toISOString() as string }) }}
                                                />
                                            </LocalizationProvider>
                                        </> : `${moment(license.expire_date).fromNow()} (${moment(license.expire_date).format('DD/MM/YYYY')})`}

                                </Grid>
                            </Hidden>
                            <Grid item xs={6} md={3}>
                                <Button variant="outlined" color="error" onClick={() => { setSelectedDeleteLicense(license) }}>Delete</Button>
                                <Button variant="outlined" color={selectedUpdateLicense?.license_key === license.license_key ? 'success' : 'warning'} sx={{ marginLeft: '5px' }} onClick={() => {
                                    if (selectedUpdateLicense?.license_key === license.license_key) {
                                        updateLicense(selectedUpdateLicense).then((response) => {
                                            callback()
                                            setSelectedUpdateLicense(null)
                                        })
                                    }
                                    setSelectedUpdateLicense(license)
                                }}>Update</Button>
                            </Grid>

                            <ConfirmDialog title={'Delete License'} isLoading={loading} open={selectedDeleteLicense?.license_key === license.license_key} onClose={() => { setSelectedDeleteLicense(null) }} onConfirm={() => {
                                setLoading(true)
                                deleteLicense(license._id || '').then((response) => {
                                    setLoading(false)
                                    callback()
                                }).catch((error) => {
                                    setLoading(false)
                                })
                            }}>Are you sure you want to delete this license?</ConfirmDialog>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}


const ManageLicence = ({ id }: {
    id: string
}) => {
    const [licenses, setLicenses] = useState<License[]>([])
    const [loading, setLoading] = useState(true)

    const fetchOrganizationLicenses = () => {
        setLoading(true)
        getOrganizationLicenses(id).then((response) => {
            setLicenses(response)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchOrganizationLicenses()
    }, [])

    return (
        <Grid container spacing={2}>
            <LoadingContainer loading={loading}>
                <Grid item xs={12} >
                    <LicenceForm callback={fetchOrganizationLicenses} organization_id={id} />
                </Grid>
                <Grid item xs={12}>
                    {licenses && licenses.length > 0 ? <LicenceTable licenses={licenses} callback={fetchOrganizationLicenses} /> : <div>No Licenses</div>}
                </Grid>
            </LoadingContainer>
        </Grid>
    )
}

export default ManageLicence