import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/system";
import { Grid, LinearProgress, Pagination } from "@mui/material";
import { Paper } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { Role } from 'src/types/role';
import { getRolesWithPagination } from 'src/services/roleService';

const Roles = () => {
    const [roles, setRoles] = React.useState<Role[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [pageData, setPageData] = React.useState<any>({
        page: 1,
        per_page: 10,
        total: 0,
    });

    const getList = () => {
        return getRolesWithPagination(
            pageData.page,
            pageData.per_page
        ).then((response) => {
            setRoles(response.data)
            setPageData({
                ...pageData,
                total: response.total_count
            })
            setLoading(false);
        });
    }

    React.useEffect(() => {
        getList();
    }, []);

    React.useEffect(() => {
        getList();
    }, [pageData.page])

    return (
        <>
            <Container maxWidth={"lg"}>
                <Box sx={{ my: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Roles
                    </Typography>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={3}>
                                {loading ? <LinearProgress /> :
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Created At</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {roles.map((role) => (
                                                    <TableRow
                                                        key={role._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {role.role_name}
                                                        </TableCell>
                                                        <TableCell align="right">{role.role_status}</TableCell>
                                                        <TableCell align="right">{role.created_at}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                aria-label="edit"
                                                                size="large"
                                                                onClick={() => window.location.href = `/roles/${role._id}`}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            {/* Delete option is disabled for now  */}
                                                            {/* <IconButton
                                                                aria-label="delete"
                                                                size="large"
                                                                onClick={() => setSelectedRole(Rol)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }

                            </Paper>
                            {pageData.total > pageData.per_page && <Grid container spacing={2} justifyContent="end" alignItems="end" mt={1}>
                                <Grid item>
                                    <Pagination count={Math.ceil(pageData.total / pageData.per_page)} color="primary" onChange={
                                        (event, value) => {
                                            setPageData({
                                                ...pageData,
                                                page: value
                                            })
                                        }
                                    } />
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default Roles