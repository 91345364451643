import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import ResponseModule from 'src/components/shared/reponseNotification';
import { createOrganization } from 'src/services/organizationService';

const CreateOrganization = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Create Organization
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        createOrganization({
                            organization_name: data.organizationName,
                            organization_description: data.organizationDescription,
                        }).then((response) => {
                            setResponse({
                                message: 'Organization created successfully',
                                error: false,
                            });
                            setLoading(false);
                        }).catch((error) => {
                            setResponse({
                                message: error.toString(),
                                error: true,
                            });
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                                <FormInputText
                                    name="organizationName"
                                    register={register}
                                    label="Organization name"
                                    errors={errors}
                                    helperText={errors.OrganizationName ? 'Please enter Organization name.' : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormInputText name="organizationDescription" register={register} label="Organization description" errors={errors}
                                    helperText={errors.OrganizationName ? 'Please enter description for Organization.' : ''}
                                />
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={12}>
                                    <ResponseModule
                                        message={response.message}
                                        error={response.error}
                                    />
                                </Grid>)}
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    loading={loading}
                                    color='success'
                                    type='submit'
                                >
                                    Create
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

export default CreateOrganization;