import { Chip, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material"
import React from "react";
import ReactJson from "react-json-view";
import { getParameters } from "src/functions/format";

const LogTable = ({ logs }: {
    logs: any
}) => {
    const [checked, setChecked] = React.useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const columns = [
        { field: 'Log Type', value: logs.type },
        { field: 'Called By', value: logs.user.email },
        { field: 'Role', value: logs.user.role },
        { field: 'Action', value: logs.request.method },
    ];

    if (logs.request.url.indexOf('?') >= 0) {
        const urlParams = JSON.parse(getParameters(logs.request.url));
        Object.keys(urlParams).map((key: string) => {
            columns.push({ field: key, value: urlParams[key] })
        })
    } else if (logs.request.url.indexOf('/') >= 0) {
        columns.push({ field: 'Url Id', value: logs.request.url })
    }

    if (logs.request.body && Object.keys(logs.request.body).length) {
        columns.push({ field: 'Has Body Parameter', value: 'Check JSON body update' })
    }


    return (
        <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={12} sx={{ padding: '0 !important' }}>
                <FormGroup>
                    <FormControlLabel sx={{ alignSelf: "end" }} control={<Switch checked={checked}
                        onChange={handleChange} />} label="JSON Mode" />
                </FormGroup>
            </Grid>
            {checked && <ReactJson src={logs} />}
            {logs?.request?.url && !checked && columns.map((column, index) => (
                // Underline box for each column
                <Grid item xs={12} md={3} key={index} >
                    <Typography variant="subtitle2" sx={{
                        color: "#b98b8b",
                        borderBottom: '1px solid #b98b8b',
                        margin: '0 10px',
                        paddingBottom: '5px'
                    }} gutterBottom>
                        {column.field}: <Chip label={column.value} />
                    </Typography>
                </Grid>
            ))}
        </Grid>
    )
}

export default LogTable