import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

interface ResponseModuleProps {
    error: boolean,
    message: string
}

const ResponseModule = ({ error, message }: ResponseModuleProps) => {

    return (
        <Stack>
            <Alert severity={error ? 'error' : 'success'}>{message}</Alert>
        </Stack>
    )

}

export default ResponseModule;