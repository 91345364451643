import { Button } from "@mui/material"
import { useState } from "react"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyButton = ({ text }: {
    text: string
}) => {
    const [copied, setCopied] = useState(false)

    const copyText = () => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 1000)
    }

    return (
        <Button onClick={copyText} sx={{
            backgroundColor: '#585860',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: '#585860',
            }
        }}>
            {copied ? 'Copied!' : text.substring(0, 10) + '...'} {!copied && <ContentCopyIcon sx={{marginLeft: '5px'}}/>}
        </Button>
    )
}

export default CopyButton