import ConfirmDialog from "../shared/confirmDialog";
import React from "react";
import { deleteUserRoleService } from "../../services/userService";
import { Typography } from "@mui/material";
import { Organization } from "src/types/organization";
import { deleteOrganization } from "src/services/organizationService";

interface DeleteUserServiceProps {
    organization: Organization,
    onClose: () => void
}
const DeleteOrganizationModal = ({ organization, onClose }: DeleteUserServiceProps) => {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <>
            <ConfirmDialog
                title="Remove Organization"
                open={open}
                isLoading={isLoading}
                onClose={() => {
                    onClose()
                }
                }
                onConfirm={() => {
                    setIsLoading(true)
                    deleteOrganization(organization._id).then((response) => {
                        if (response) {
                            window.location.reload();
                            setIsLoading(false)
                        }
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }}
            >
                <Typography>
                    You are about to delete organization and their connections. Are you sure you want to proceed?
                </Typography>
            </ConfirmDialog>

        </>

    )
}

export default DeleteOrganizationModal