import { getAxiosWithToken, deleteAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from './helpers/axiosHelper';

export const listAllUser = async () => {
    return getAxiosWithToken({ url: '/users?list_all=true' }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const listWithPagination = async (page: number, per_page: number) => {
    return getAxiosWithToken({ url: `/users?page=${page}&per_page=${per_page}` }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const deleteUser = async (id: string) => {
    return deleteAxiosWithToken({ url: `/users/${id}` }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const createUser = async (user: {
    username: string,
    password: string,
    first_name: string,
    last_name: string,
    email: string,
    address: string,
    mobile_phone: string,
    organization_id: string,
    birth_date: string,
}) => {
    return postAxiosWithToken({ url: '/users?is_debug=true', body: user }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            const err = response.data.errors[0]
            throw new Error(err.param + ' ' + err.msg);
        }
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const getUser = async (id: string) => {
    return getAxiosWithToken({ url: `/users/${id}` }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const getUserServices = async (id: string) => {
    return getAxiosWithToken({ url: `/users/${id}/services/` }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const setUserRoleService = async (id: string, role_id: string, service_id: string) => {
    return postAxiosWithToken({ url: `/auth/authorize`, body: { user_id: id, role_id: role_id, service_id: service_id } }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const changeUserRoleService = async (id: string, role_id: string, service_id: string) => {
    return postAxiosWithToken({ url: `/auth/change-role`, body: { user_id: id, role_id: role_id, service_id: service_id } }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const deleteUserRoleService = async (id: string, role_id: string, service_id: string) => {
    return postAxiosWithToken({ url: `/auth/delete-authorization?is_debug=true`, body: { user_id: id, role_id: role_id, service_id: service_id } }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const updateUserPicture = async (id: string) => {
    return getAxiosWithToken({ url: `/users/${id}/avatar` }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const updateUser = async (id: string, user: {
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    mobile_phone: string,
    organization_id: string,
    birth_date: string,
    address: string,
}) => {
    return putAxiosWithToken({ url: `/users/${id}`, body: user }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            const err = response.data.errors[0]
            throw new Error(err.param + ' ' + err.msg);
        }
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}


export const changePassword = async (id: string, oldPassword: string, newPassword: string) => {
    return postAxiosWithToken({ url: `/users/${id}/change-password`, body: { old_password: oldPassword, new_password: newPassword } }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const updatePersonal = async (id: string, emergency_contact: {
    name: string,
    surname: string,
    phone: string,
}) => {
    return postAxiosWithToken({ url: `/users/${id}/update-personal`, body: { emergency_contact: emergency_contact} }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const changePasswordWithToken = async (token: string, password: string) => {
    return postAxiosWithToken({ url: `/users/forgot-password-confirm`, body: { password: password, token: token } }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const resetPassword = async (email: string) => {
    return postAxiosWithToken({ url: `/users/forgot-password`, body: { email: email } }).then((response: any) => {
        return response.data
    }
    ).catch((error: any) => {
        throw new Error(error)
    })
}

export const searchUsers = async (page: number, per_page: number, filterOptions: { organization: string, status: string, search: string }) => {
    return getAxiosWithToken({
        url: `/users/search?search_query=${filterOptions.search}${filterOptions.organization &&
            ('&organization_id=' + filterOptions.organization)}${filterOptions.status && ('&status=' + filterOptions.status)}&is_debug=true&page=${page}&per_page=${per_page}`
    }
    ).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}