import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmDialog = ({title, children, open, onClose, onConfirm, isLoading}: any) => {

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{padding:'12px'}}>
                {isLoading ?
                    <CircularProgress/> : <>
                        <Button
                            variant="contained"
                            onClick={() => onClose()}
                            color="inherit"
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                onConfirm();
                            }}
                            color="error"
                        >
                            Yes
                        </Button>
                    </>}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;