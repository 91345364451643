import { Service } from "src/types/service";
import ConfirmDialog from "../shared/confirmDialog";
import React from "react";
import { deleteUser } from "../../services/userService";
import { Typography } from "@mui/material";

interface DeleteServiceModalProps {
    service: Service,
    onClose: () => void
}
const DeleteServiceModal = ({ service, onClose }: DeleteServiceModalProps) => {
    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <>
            <ConfirmDialog
                title="Delete Service"
                open={open}
                isLoading={isLoading}
                onClose={() => {
                    onClose()
                    setOpen(false)
                }
                }
                onConfirm={() => {
                    setIsLoading(true)
                    deleteUser(service._id).then((response) => {
                        console.log('res',response)
                        if (response) {
                            setOpen(false)
                            setIsLoading(false)
                        }
                    }).catch(() => {
                        setIsLoading(false)
                    })
                }}
            >
                <Typography>
                    You are about to delete ({service.service_name}) from the system. Are you sure you want to proceed?
                </Typography>
            </ConfirmDialog>

        </>

    )
}

export default DeleteServiceModal