import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import UserInfoCard from 'src/components/userInfoCard';
import { useAuth } from 'qreal-auth-provider/src';
import { User, UserServices } from 'src/types/user';
import UserServiceCard from 'src/components/userServiceCard';
import { getUserServices } from 'src/services/userService';
import UserChangePasswordCard from 'src/components/userChangePassword';
import PublicView from 'src/components/layouts/publicView';
import UserPersonalInfoCard from 'src/components/userPersonalInfoCard';
import { ROLE_KEYS } from 'src/enums/authKeys';

const UserProfileUpdate = () => {
    const [userState, setUserState] = useState<User>({} as User);
    const [userServices, setUserServices] = useState<UserServices[]>();
    const { user } = useAuth();

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setUserState({
                ...userState,
                _id: user._id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                user_avatar: user.user_avatar,
                organization: user.organization,
            });
        }
        if (user && Object.keys(user).length > 0 && !userServices) {
            getUserServices(user._id as string).then((response) => {
                setUserServices(response);
            });
        }
    }, [user]);

    return (
        <PublicView>
            <UserInfoCard user={userState as User} />

            <Box sx={{ mb: 2, px: 4 }} />

            {userServices && <UserServiceCard services={userServices} />}

            <Box sx={{ mb: 2, px: 4 }} />

            {(user && Object.keys(user).length > 0) && user.role !== ROLE_KEYS.CUSTOMER && <UserPersonalInfoCard id={userState._id} />}

            <Box sx={{ mb: 2, px: 4 }} />

            {(user && Object.keys(user).length > 0) && <UserChangePasswordCard id={userState._id} />}

            <Box sx={{ my: 5, py: 5 }} />
        </PublicView>
    );
}

export default UserProfileUpdate;