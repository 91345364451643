import { Button, FormControl, Grid, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { getAllOrganizations } from "src/services/organizationService";
import { Organization } from "src/types/organization";
import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    handleSubmit: (data: any) => void;
}

const emptyFilterOptions = {
    search: '',
    organization: '',
    status: '',
}

const SearchFilter = ({ handleSubmit }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [organizations, setOrganizations] = React.useState<Organization[]>([]);
    const [filterOptions, setFilterOptions] = React.useState<any>(emptyFilterOptions);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    useEffect(() => {
        getAllOrganizations().then((response) => {
            setOrganizations(response)
        });
    }, []);

    const onChange = (event: SelectChangeEvent<any>) => {
        const updateData = {
            ...filterOptions,
            [event.target.name]: event.target.value
        }
        setFilterOptions(updateData)
    }
    const id = open ? 'filter-popover' : undefined;


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                <TuneIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                onClose={() => {
                    setOpen(false)
                    handleClose()
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ marginTop: '10px' }}
                PaperProps={{
                    style: {
                        padding: '10px',
                        width: '300px',
                        height: 'auto',
                        overflow: 'auto'
                    },
                }
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            label='Search'
                            size='small'
                            value={filterOptions.search}
                            fullWidth
                            name="search"
                            onChange={(e) => {
                                onChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value
                                    }

                                } as any)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <InputLabel size="small" id={`dropdown-id-filter-status`}>{'Status'}</InputLabel>
                            <Select name="status" size="small" labelId={`dropdown-id-filter-status`} onChange={(e) => { onChange(e) }} value={filterOptions.status} label={'status'}>
                                <MenuItem value='ACTIVE'>ACTIVE</MenuItem>
                                <MenuItem value='PASSIVE'>PASSIVE</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                        {(organizations.length > 0) &&
                            <FormControl fullWidth>
                                <InputLabel size="small" id={`dropdown-id-filter-organization`}>{'Organization'}</InputLabel>
                                <Select name="organization" size="small" labelId={`dropdown-id-filter-organization`} onChange={(e) => { onChange(e) }} value={filterOptions.organization} label={'Organization'}>
                                    {organizations.map((organization) => {
                                        return (
                                            <MenuItem key={organization._id} value={organization._id}>
                                                {organization.organization_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2}>
                            <Button fullWidth type="submit" size={'small'} variant="contained" onClick={
                                () => {
                                    setOpen(false)
                                    handleSubmit(filterOptions)
                                }
                            }>
                                <SearchIcon sx={{ color: 'white', cursor: 'pointer' }} />
                            </Button>
                            <Button fullWidth type="submit" color="warning" size={'small'} variant="contained" onClick={
                                () => {
                                    setFilterOptions(emptyFilterOptions)
                                    handleSubmit(emptyFilterOptions)
                                }
                            }>
                                Clear
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>
            </Popover>

        </>
    )

}

export default SearchFilter