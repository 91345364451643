import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormDatePicker } from 'src/components/formElements/formDatePicker';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormInputText } from 'src/components/formElements/formInputText';
import ResponseModule from 'src/components/shared/reponseNotification';
import UserPermissionModule from 'src/components/user/userPermissionModule';
import { generatePassword } from 'src/services/helpers/numberHelper';
import { sendWelcomeMail } from 'src/services/mailService';
import { getAllOrganizations } from 'src/services/organizationService';
import { createUser } from 'src/services/userService';
import { Organization } from 'src/types/organization';

const CreateUser = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, },
    } = useForm();
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });
    const [loading, setLoading] = useState(false);
    const [isUserCreatedSuccessfully, setIsUserCreatedSuccessfully] = useState(false);
    const [userId, setUserId] = useState('123123');
    const [userEmail, setUserEmail] = useState('');
    const [organizations, setOrganizations] = useState<Organization[]>([]);

    useEffect(() => {
        getAllOrganizations().then((res) => {
            setOrganizations(res);
        }).catch((error) => {
            setResponse({
                message: error.toString(),
                error: true,
            });
        });
    }, []);

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Create User
                    </Typography>
                    <form onSubmit={handleSubmit((data) => {
                        setLoading(true);
                        setIsUserCreatedSuccessfully(false)
                        const pass = generatePassword();
                        createUser({
                            username: data.userName,
                            password: pass,
                            first_name: data.name,
                            last_name: data.lastName,
                            email: data.email,
                            address: data.address,
                            mobile_phone: data.phone,
                            organization_id: data.organization,
                            birth_date: data.birthDate,
                        }).then((res) => {
                            setUserId(res.data[0]._id)
                            setUserEmail(data.email)
                            sendWelcomeMail({
                                email: data.email,
                                name: data.name,
                                password: pass,
                            }).then(() => {
                                setLoading(false);
                                setResponse({
                                    message: 'User created successfully',
                                    error: false,
                                });
                                setIsUserCreatedSuccessfully(true)
                            }).catch((error) => {
                                setResponse({
                                    message: error.toString(),
                                    error: true,
                                });
                                setLoading(false);
                            })
                        }).catch((error) => {
                            console.log('submit error', error);
                            setResponse({
                                message: error.toString(),
                                error: true,
                            });
                            setLoading(false);
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="userName"
                                    register={register}
                                    label="Username"
                                    errors={errors}
                                    helperText={'Please enter username.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="name"
                                    register={register}
                                    label="Name"
                                    errors={errors}
                                    helperText={'Please enter name.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="lastName"
                                    register={register}
                                    label="Last Name"
                                    errors={errors}
                                    helperText={'Please enter last name.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="email"
                                    register={register}
                                    label="Email"
                                    errors={errors}
                                    helperText={'Please enter email.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="phone"
                                    register={register}
                                    mask='0(999) 999-99-99'
                                    control={control}
                                    label="Phone Number"
                                    errors={errors}
                                    helperText={'Please enter phone number.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormDatePicker
                                    name="birthDate"
                                    register={register}
                                    label="Birthday"
                                    defaultValue={new Date()}
                                    control={control}
                                    errors={errors}
                                    helperText={'Please enter birthday.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormInputText
                                    name="address"
                                    register={register}
                                    label="Address"
                                    errors={errors}
                                    helperText={'Please enter address.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {organizations.length > 0 &&
                                    <FormInputDropdown name="organization" control={control} label="Organization" options={organizations.map((organization: Organization) => ({ label: organization.organization_name, value: organization._id }))} />
                                }
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={6}>
                                    <ResponseModule
                                        message={response.message}
                                        error={response.error}
                                    />
                                </Grid>)}
                            {isUserCreatedSuccessfully && userId !== null &&
                                <Grid item xs={6}>
                                    <Box display={"flex"} justifyContent={"flex-end"}>
                                        <Button variant="outlined" href={`/users/${userId}`} target='_blank'>Go to User</Button>
                                    </Box>
                                </Grid>
                            }

                            {isUserCreatedSuccessfully && userId !== null && <>
                                <UserPermissionModule id={userId} email={userEmail} />
                            </>}
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    loading={loading}
                                    color='success'
                                    type='submit'
                                >
                                    Create
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
}

export default CreateUser;