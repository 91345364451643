import { getAxiosWithToken, deleteAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from './helpers/axiosHelper';

export const list = async () => {
    return getAxiosWithToken({ url: '/services?list_all=true' }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const listWithPagination = async (page: number, per_page: number) => {
    return getAxiosWithToken({ url: `/services?page=${page}&per_page=${per_page}` }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const createService = async (service: {
    service_name: string,
    service_description: string,
    service_domain: string
}) => {
    return postAxiosWithToken({ url: '/services?is_debug=true', body: service }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            throw 'Something went wrong';
        }
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const updateService = async (service: {
    _id: string,
    service_status: string,
    service_description: string,
    service_domain: string,
    service_log_groups: string,
}) => {
    return putAxiosWithToken({
        url: `/services/${service._id}`, body: {
            service_description: service.service_description,
            service_status: service.service_status,
            service_domain: service.service_domain,
            service_log_groups: service.service_log_groups
        }
    }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}