import { Card, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useAuth } from "qreal-auth-provider/src";
import { authKeys, checkAuth } from "src/enums/authKeys";
import useContextMenu from "src/hooks/rightClickHook";

const UserRightClickMenu = ({ selectedRow, points, setClicked }: any) => {
    const { authList } = useAuth();

    return <Card sx={{
        position: 'absolute',
        width: 200,
        top: points.y,
        left: points.x,
    }}>
        <List>
            {checkAuth(authList, authKeys.userUpdate) && <ListItem disablePadding>
                <ListItemButton>
                    <ListItemText onClick={() => {
                        window.open(`/users/${selectedRow._id}`, '_blank');
                        setClicked(false);
                    }} primary="Open on new tab" />
                </ListItemButton>
            </ListItem>
            }
        </List>
    </Card>

}

export default UserRightClickMenu;