import { Box, LinearProgress } from "@mui/material";

type Loading = {
    loading: boolean;
    children: React.ReactNode;
};

const LoadingContainer = ({ loading, children }: Loading) => {
    return (
        <>
            {
                loading ? <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <LinearProgress />
                </Box> : children
            }
        </>
    );
};

export default LoadingContainer;