import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/system";
import { Alert, Button, Card, Grid, IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import { Log, LogGroup } from 'src/types/log';
import { searchLog } from 'src/services/logService';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { list } from 'src/services/services';
import LoadingContainer from 'src/components/shared/loadingContainer';
import { useForm } from 'react-hook-form';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { FormAutoComplete } from 'src/components/formElements/formAutoComplete';
import { User } from 'src/types/user';
import { listAllUser } from 'src/services/userService';
import LogTable from 'src/components/logs/logTable';

const LogSearch = () => {
    const [logs, setLogs] = React.useState<any[]>([]);
    const [selectedLog, setSelectedLog] = React.useState<any>(null);
    const [searchQuery, setSearchQuery] = React.useState<string>('');
    const [services, setServices] = React.useState<any[]>([]);
    const [users, setUsers] = React.useState<User[]>([]);
    const [loadState, setLoadState] = React.useState<any>({
        loading: false,
        message: ''
    });

    const {
        control,
        getValues
    } = useForm();

    const getLogs = () => {
        setLoadState({
            loading: true,
            message: ''
        })
        setLogs([]);
        const { service, time, user, action } = getValues();

        if (!service) { setLoadState({ loading: false, message: 'SearchError: Please select a service' }); return; }

        const selectedService = services.find((serviceSingle) => serviceSingle._id == service)

        Promise.allSettled(selectedService.service_log_groups.map((logGroup: LogGroup) => {
            return searchLog({
                "log_group_name": logGroup.group,
                "region": logGroup.region,
                "query_string": `${searchQuery}${user ? `,${user}` : ''}${(action && action != 'all') ? `,${action}` : ''}`,
                "start_date": new Date(new Date().getTime() - (time * 24 * 60 * 60 * 1000)).toISOString(), // Subtract days (Day * 24 * 60 * 60 * 1000 milliseconds) from the current date
                "end_date": new Date().toISOString(),
            })
        })).then((results) => {
            const logs: Log[] = [];
            results.forEach((result, index) => {
                if (result.status == 'fulfilled') {
                    const logList: {
                        value: any,
                        date: string
                    }[] = [];

                    result.value.map((log: Log[]) => {
                        log.map((l: Log, index) => {
                            if (l.field == '@message') {
                                const jsonStartIndex = l.value.indexOf('{');
                                try {
                                    const parsedJson = JSON.parse(l.value.substring(jsonStartIndex));
                                    logList.push({
                                        value: parsedJson,
                                        date: log[0].value
                                    });
                                } catch (error) {
                                    return false;
                                }

                            }
                        })
                    })
                    setLogs((prevLogs) => [...prevLogs, ...logList])
                } else {
                    setLoadState((prevState: any) => ({
                        message: prevState.message + (selectedService.service_log_groups[index].group + ': ' + result.reason.message + '</br>')
                    }))
                }
            })

            setLoadState((prevState: any) => ({
                loading: false,
                message: prevState.message
            }))

        }).catch((error) => {
            setLoadState({
                loading: false,
                message: error.message
            })
        })

        return;
    }

    React.useEffect(() => {
        list().then((response) => {
            setServices(response)
        })

        listAllUser().then((response) => {
            setUsers(response)
        })
    }, []);

    return (
        <>
            <Container maxWidth={"lg"}>
                <Box sx={{ my: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Log History Search
                    </Typography>
                    <Grid container spacing={3} alignItems={'center'}>
                        <Grid item xs={4}>
                            <TextField onChange={(e) => {
                                setSearchQuery(e.target.value)
                            }} fullWidth label="Search Query" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <FormAutoComplete name='user' label='User' control={control} options={users.map((user) => ({ label: `${user.email}`, value: user._id }))} />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInputDropdown name="service" control={control} label="Service" options={services.map((service) => ({ label: service.service_name, value: service._id }))} />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInputDropdown name="action" defaultValue={'all'} control={control} label="Action" options={[
                                { label: 'All', value: 'all' },
                                { label: 'Access', value: 'GET' },
                                { label: 'Create', value: 'POST' },
                                { label: 'Update', value: 'PUT' },
                                { label: 'Delete', value: 'DELETE' },
                            ]} />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInputDropdown name="time" control={control} defaultValue='1' label="Time" options={[
                                { label: '1 Day', value: '1' },
                                { label: '1 Week', value: '7' },
                                { label: '1 Month', value: '30' },
                                { label: '1 Year', value: '365' },
                            ]} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => {
                                getLogs();
                            }} fullWidth variant="contained">Search</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <List dense={false}>
                                    {
                                        logs.length > 0 ? <>
                                            {loadState.message && <Alert severity="error"><Typography dangerouslySetInnerHTML={{ __html: loadState.message }}></Typography></Alert>}
                                            {logs.map((log) => {
                                                if (log) {
                                                    return (
                                                        <>
                                                            <ListItem key={`Log-${log.date + Math.random()}`} onClick={() => {
                                                                setSelectedLog(log.value)
                                                            }}>
                                                                <ListItemText
                                                                    primary={log.date}
                                                                />
                                                                <Typography variant={"body1"} component={"p"} margin={0} gutterBottom>
                                                                    {log.value?.request?.url}
                                                                </Typography>

                                                                <IconButton
                                                                    aria-label="show more"
                                                                    aria-controls="log"
                                                                    aria-haspopup="true"
                                                                >
                                                                    <ExpandMoreIcon />
                                                                </IconButton>
                                                            </ListItem>

                                                            {selectedLog && selectedLog == log.value && <>

                                                                {<LogTable logs={log.value} />}
                                                            </>}
                                                        </>
                                                    )
                                                }
                                                return '';
                                            })}
                                        </> :
                                            <>
                                                <LoadingContainer loading={loadState.loading}>
                                                    <Typography textAlign={'center'} variant={"body1"} component={"p"} margin={0} gutterBottom>
                                                        {loadState.message || 'No data found'}
                                                    </Typography>
                                                </LoadingContainer>
                                            </>
                                    }
                                </List>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default LogSearch