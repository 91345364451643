import { Service } from "src/types/service";
import ConfirmDialog from "../shared/confirmDialog";
import React from "react";
import { deleteUser } from "../../services/userService";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormInputDropdown } from "../../components/formElements/formDropDown"
import { authorizeUser } from "src/services/authService";
import { sendAccessNotification } from "src/services/mailService";

interface AddUserServiceModalProps {
    roles: any,
    services: any,
    userId: string,
    userEmail: string,
    onClose: (newService: any) => void
}
const AddUserServiceModal = ({ roles, userId, services, userEmail, onClose }: AddUserServiceModalProps) => {
    const {
        control,
        getValues
    } = useForm();

    const [open, setOpen] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    return (
        <>
            <ConfirmDialog
                title="Add User To Service"
                open={open}
                isLoading={isLoading}
                onClose={() => {
                    onClose(null)
                    setOpen(false)
                }
                }
                onConfirm={() => {
                    const { service, role } = getValues();
                    if (!service || !role) {
                        setError(true);
                        return;
                    }
                    setIsLoading(true);

                    authorizeUser({
                        user_id: userId,
                        service_id: service,
                        role_id: role
                    }).then((response) => {
                        console.log('auth res', response);
                        if (response) {
                            const selectedService = services.find((s: Service) => s._id === service);
                            sendAccessNotification({
                                email: userEmail,
                                domain: selectedService.service_domain,
                                service: selectedService.service_name,
                            }).then(() => {
                                onClose({
                                    ...selectedService,
                                    ...{
                                        role: [roles.find((r: any) => r._id === role)]
                                    }
                                });
                                setOpen(false);
                            }).catch((error) => {
                                onClose(null);
                                setOpen(false);
                            })
                        }
                    })
                }}
            >
                <Grid container spacing={2} width={{ xs: '250px', md: '500px' }}>
                    <Grid item xs={12} md={6}>
                        <FormInputDropdown name="service" control={control} label="Service" options={services.map((service: Service) => ({ label: service.service_name, value: service._id }))} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormInputDropdown name="role" control={control} label="Role" options={roles.map((role: any) => ({ label: role.role_name, value: role._id }))} />
                    </Grid>
                    {error && <Grid item xs={12}>
                        <Typography variant="body2" color="error">Please be sure that you have selected fields...</Typography>
                    </Grid>}
                </Grid>
            </ConfirmDialog>

        </>

    )
}

export default AddUserServiceModal