import { Autocomplete, Button, Grid, Stack, TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from "react";
import { Permission } from "src/types/permission";
import { authKeys, checkAuth } from "src/enums/authKeys";
import { useAuth } from "qreal-auth-provider/src";

interface PermissionTableProps {
    permissions: Permission[];
    allPermissions: Permission[];
    addPermission: (permission: Permission) => void;
    removePermission: (permission: Permission) => void;
}

const PermissionTable = ({ permissions, allPermissions, addPermission, removePermission }: PermissionTableProps) => {
    const [selectedPermission, setSelectedPermission] = useState<Permission | null>();
    const { authList } = useAuth();

    return (
        <Grid container spacing={2} pt={3}>
            <Grid item xs={12} md={8}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label=" Permissions Table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Permission Name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {permissions && permissions.map((permission) => (
                                <TableRow
                                    key={permission.permission_name + '-permission'}
                                >
                                    <TableCell component="th" scope="row">
                                        {permission.permission_name + ' '}
                                    </TableCell>
                                    <TableCell>
                                        {checkAuth(authList, authKeys.roleUpdate) && <RemoveCircleIcon color="error" sx={{ cursor: 'pointer' }} onClick={() => { removePermission(permission) }} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {checkAuth(authList, authKeys.roleUpdate) && <Grid item xs={12} md={4}>
                <Stack direction={'row'}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={allPermissions}
                        getOptionLabel={(option: any) => option.permission_name}
                        onChange={(event, newValue) => {
                            setSelectedPermission(newValue as Permission);
                            return newValue;
                        }}
                        value={selectedPermission || null}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Add new permission" />}
                    />
                    <Button onClick={() => {
                        addPermission(selectedPermission as Permission);
                        setSelectedPermission(null);
                    }} variant='contained' color='success'>Add</Button>
                </Stack>
            </Grid>}
        </Grid>
    );
};

export default PermissionTable;