import './App.css';

// Pages
import Login from "./pages/Login";
import Users from './pages/users';
import Services from './pages/services/list';
import CreateService from './pages/services/create';
import CreateUser from './pages/users/create';
import UserDetail from './pages/users/edit';

// Routes
import { Route, Routes } from "react-router-dom"
import { AuthProvider } from 'qreal-auth-provider/src';
import { ProtectedRoute } from './hooks/protectedRoute';
import EditService from './pages/services/edit';
import Roles from './pages/roles/list';
import EditRole from './pages/roles/edit';
import UserProfileUpdate from './pages/users/profile';
import { authKeys } from './enums/authKeys';
import Organizations from './pages/organizations/list';
import CreateOrganization from './pages/organizations/create';
import EditOrganization from './pages/organizations/edit';
import UserForgotPassword from './pages/users/forgot-password';
import ChangePassword from './pages/users/change-password';
import LogSearch from './pages/logs/search';
import CreateRole from './pages/roles/create';

function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/sign-in" element={<Login />} />
          <Route path="/" element={<ProtectedRoute pagePermissionKey={authKeys.userList}><Users /></ProtectedRoute>} />
          <Route path="/services/list" element={<ProtectedRoute pagePermissionKey={authKeys.serviceList}><Services /></ProtectedRoute>} />
          <Route path="/services/create" element={<ProtectedRoute pagePermissionKey={authKeys.serviceCreate}><CreateService /></ProtectedRoute>} />
          <Route path="/users/create" element={<ProtectedRoute pagePermissionKey={authKeys.userCreate}><CreateUser /></ProtectedRoute>} />
          <Route path="/users/:id" element={<ProtectedRoute pagePermissionKey={authKeys.userUpdate}><UserDetail /></ProtectedRoute>} />
          <Route path="/services/:id" element={<ProtectedRoute pagePermissionKey={authKeys.serviceUpdate}><EditService /></ProtectedRoute>} />
          <Route path="/roles/list" element={<ProtectedRoute pagePermissionKey={authKeys.roleList}><Roles /></ProtectedRoute>} />
          <Route path="/roles/create" element={<ProtectedRoute pagePermissionKey={authKeys.roleList}><CreateRole /></ProtectedRoute>} />
          <Route path="/roles/:id" element={<ProtectedRoute pagePermissionKey={authKeys.roleList}><EditRole /></ProtectedRoute>} />
          <Route path="/organizations/list" element={<ProtectedRoute pagePermissionKey={authKeys.organizationList}><Organizations /></ProtectedRoute>} />
          <Route path="/organizations/create" element={<ProtectedRoute pagePermissionKey={authKeys.organizationCreate}><CreateOrganization /></ProtectedRoute>} />
          <Route path="/organizations/:id" element={<ProtectedRoute pagePermissionKey={authKeys.organizationUpdate}><EditOrganization /></ProtectedRoute>} />
          <Route path="/log/history" element={<ProtectedRoute pagePermissionKey={authKeys.logSearch}><LogSearch /></ProtectedRoute>} />
          <Route path="/user/profile-update" element={<UserProfileUpdate />} />
          <Route path="/user/forgot-password" element={<UserForgotPassword />} />
          <Route path="/user/forgot-password/:id" element={<ChangePassword />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
