import { Avatar, Divider, Grid, Stack, Typography } from "@mui/material";
import { User, UserServices } from "src/types/user";

interface UserServiceCardProps {
    services: UserServices[]
}

const UserServiceCard = ({ services }: UserServiceCardProps) => {

    return <Grid container justifyContent='center'>
        <Grid item xs={12} md={6} sx={{ border: '1px solid #80808033' }}>
            <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    User Permissions
                </Typography>
                <Divider sx={{ mb: 4 }} />
                <Grid container spacing={3} justifyContent='space-between'>
                    {services && services.map((permission) => {
                        return <Grid item xs={6} alignItems='center'>
                            <Typography variant='body1'
                                sx={{ fontWeight: 'bold' }}
                            >
                                {permission.service[0].service_name}
                            </Typography>
                            <Typography variant='body1'>
                                {permission.role[0].role_key.toLocaleLowerCase()}
                            </Typography>
                        </Grid>
                    })}
                </Grid>
            </Stack>
        </Grid>
    </Grid>
}

export default UserServiceCard;