import { useParams } from "react-router-dom";
import ChangePasswordForm from "src/components/changePasswordForm";
import PublicView from "src/components/layouts/publicView";

const ChangePassword = () => {
    let { id } = useParams();

    return (
        <PublicView>
            {id && <ChangePasswordForm id={id} />}
        </PublicView>
    )
}
export default ChangePassword;