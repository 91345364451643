import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from 'qreal-auth-provider/src';
import { useNavigate } from 'react-router-dom';
import { errorMessages } from '../enums/errorMessages';
import { CircularProgress, Avatar, Button, CssBaseline, TextField, Alert, Link, Grid, Box, Typography, Container } from '@mui/material';
import { useEffect } from 'react';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.qreal.io/">
                QReal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function Login() {
    const { token, onLogin } = useAuth();
    const [error, setError] = React.useState<"INVALID_AUTHORIZATION" | null>(null);
    const [loading, setLoading] = React.useState(false);

    let navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setError(null);
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        onLogin({ email: data.get('email'), password: data.get('password') }).then((response) => {
            setLoading(false);
            if (response.success) {
                navigate('/')
            } else {
                setError(response.message);
            }
        });
    };

    const tokenListener = (event: any) => {
        if (event.data && event.data.token && !event.data.type.includes('webpack')) {
            console.log('testtt', event.data, event.data.type, event.data.type !== "webpackInvalid")
            localStorage.setItem("authToken", event.data.token);
        }
    }

    useEffect(() => {
        window.addEventListener('message', tokenListener);

        return () => {
            window.removeEventListener('message', tokenListener);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1 }} src={'/qreal.ico'}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Identity Management Sign In
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            type={'email'}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {loading ?
                            <Grid container justifyContent={'center'} py={2}>
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid> :
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        }
                        {error && <Alert severity="error">{errorMessages[error] || error}</Alert>}
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}