export const generatePassword = (length = 10, specialChars = true) => {
    const alphaCodesArray = Array.from(Array(26)).map((e, i) => i + 65);
    const uppercaseAlphabetArray = alphaCodesArray.map((letterCode) => String.fromCharCode(letterCode));
    const lowercaseAlphabetArray = uppercaseAlphabetArray.map(e => e.toLowerCase());

    const uppercaseAlphabet = [...uppercaseAlphabetArray].join('');
    const lowercaseAlphabet = [...lowercaseAlphabetArray].join('');
    const numbers = [...Array(10).keys()].join('');
    const specialSymbols = typeof specialChars === 'string' ? specialChars : (specialChars ? "!@#$%^&*?" : "");

    const characters = `${lowercaseAlphabet}${numbers}${uppercaseAlphabet}${specialSymbols}`;

    return [...Array(length).keys()].reduce(acc => acc.concat(characters.charAt(Math.floor(Math.random() * characters.length))), '');
}