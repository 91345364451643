import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'src/components/formElements/formInputText';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { getUser, getUserServices, updateUser } from 'src/services/userService';
import { useParams } from 'react-router-dom';
import { User } from 'src/types/user';
import { getAllRoles } from 'src/services/roleService';
import UserRow from 'src/components/userRoleRow';
import AddUserServiceModal from 'src/components/modals/addServiceModal';
import { list } from 'src/services/services';
import ResponseModule from 'src/components/shared/reponseNotification';
import { getAllOrganizations } from 'src/services/organizationService';
import { FormInputDropdown } from 'src/components/formElements/formDropDown';
import { Organization } from 'src/types/organization';
import { FormDatePicker } from 'src/components/formElements/formDatePicker';

const UserDetail = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const [user, setUser] = useState<User>();
    const [services, setServices] = useState<any[]>([])
    const [allServices, setAllServices] = useState<any[]>([])
    const [roles, setRoles] = useState<any[]>([])
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });
    const [organizations, setOrganizations] = useState<Organization[]>([]);

    let { id } = useParams();

    useEffect(() => {
        Promise.all([getUser(id as string), getUserServices(id as string), getAllRoles(), getAllOrganizations()]).then(responses => {
            setLoading(false);
            setUser(responses[0][0]);
            setServices(responses[1]);
            setRoles(responses[2]);
            setOrganizations(responses[3]);
        });
    }, []);

    const handleOpenModal = () => {
        if (!allServices.length) {
            list().then((response) => {
                // Filter services that are already added to the user
                setAllServices(response.filter((service: any) => !services.find((userService: any) => userService.service[0]._id === service._id)));
                setOpenModal(true);
            })
        } else {
            setOpenModal(true);
        }
    }

    return (
        <Container maxWidth={"lg"}>
            <Paper>
                {user ? <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <Typography variant={"h4"} component={"h1"} gutterBottom>
                        Update User
                    </Typography>
                    <Divider sx={{ mb: 4 }} />
                    <form onSubmit={handleSubmit((data) => {
                        console.log(data);
                        setLoading(true);
                        updateUser(id as string, {
                            username: data.userName,
                            first_name: data.name,
                            last_name: data.lastName,
                            email: data.email,
                            mobile_phone: data.phone,
                            address: data.address,
                            organization_id: data.organization,
                            birth_date: data.birthDate ? data.birthDate.$d : '',
                        }).then(() => {
                            setLoading(false);
                            setResponse({
                                message: 'User updated successfully',
                                error: false,
                            });
                        }).catch(() => {
                            setLoading(false);
                            setResponse({
                                message: 'Error updating user',
                                error: true,
                            });
                        })
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="userName"
                                    register={register}
                                    label="Username"
                                    defaultValue={user?.username}
                                    errors={errors}
                                    helperText={'Please enter username.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="name"
                                    register={register}
                                    defaultValue={user?.first_name}
                                    label="Name"
                                    errors={errors}
                                    helperText={'Please enter name.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="lastName"
                                    register={register}
                                    defaultValue={user?.last_name}
                                    label="Last Name"
                                    errors={errors}
                                    helperText={'Please enter last name.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="email"
                                    register={register}
                                    defaultValue={user?.email}
                                    label="Email"
                                    errors={errors}
                                    helperText={'Please enter email.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormInputText
                                    name="phone"
                                    register={register}
                                    mask='0(999) 999-99-99'
                                    control={control}
                                    defaultValue={user?.mobile_phone}
                                    label="Phone Number"
                                    errors={errors}
                                    helperText={'Please enter phone number.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormDatePicker
                                    name="birthDate"
                                    register={register}
                                    defaultValue={user?.birth_date}
                                    label="Birthday"
                                    control={control} 
                                    errors={errors}
                                    helperText={'Please enter birthday.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormInputText
                                    name="address"
                                    register={register}
                                    label="Address"
                                    errors={errors}
                                    defaultValue={user?.address}
                                    helperText={'Please enter address.'}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {organizations.length > 0 &&
                                    <FormInputDropdown defaultValue={user.organization_id} name="organization" control={control} label="Organization" options={organizations.map((organization: Organization) => ({ label: organization.organization_name, value: organization._id }))} />
                                }
                            </Grid>
                            {response.message && !loading && (
                                <Grid item xs={12}>
                                    <ResponseModule
                                        message={response.message}
                                        error={response.error}
                                    />
                                </Grid>)}
                            <Grid item xs={12} sx={{ textAlign: 'end' }}>
                                <LoadingButton
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color='success'
                                    type='submit'
                                    loading={loading}
                                >
                                    Update
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Box> : <Box sx={{ my: 4, py: 2, px: 4 }}>
                    <LinearProgress />
                </Box>}
            </Paper>

            <Paper>
                <Box sx={{ py: 2, px: 4 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {services.map((service) => (
                                <UserRow
                                    name={service?.service[0]?.service_name}
                                    userId={id as string}
                                    serviceId={service?.service[0]?._id}
                                    key={service.id}
                                    selectedRoleId={service?.role[0]?._id}
                                    roles={roles}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <Grid item xs={12} sx={{ textAlign: 'end', mt: 3 }}>
                        <Button variant="outlined" color='success' onClick={() => handleOpenModal()}>
                            Authorize
                        </Button>
                    </Grid>
                </Box>
            </Paper>
            {openModal && <AddUserServiceModal
                services={allServices}
                onClose={(newService) => {
                    if (newService) {
                        setServices([...services, {
                            service: [newService],
                            role: newService.role
                        }])
                    }
                    setOpenModal(false)
                }}
                userId={id as string}
                userEmail={user?.email || ''}
                roles={roles}
            />}
        </Container>
    );
}

export default UserDetail;