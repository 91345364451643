import { Navigate } from 'react-router-dom';
import { checkAuth } from '../enums/authKeys';
import { useAuth } from 'qreal-auth-provider/src';
import Navbar from '../components/shared/navbar';

export const ProtectedRoute = ({ children, pagePermissionKey = '' }: {
    children: React.ReactNode;
    pagePermissionKey?: string;
}): any => {
    const { token, isPermissionLoaded, authList } = useAuth();

    if (!token) {
        return <Navigate to="/sign-in" replace />;
    }
    else if (!isPermissionLoaded) {
        return null;
    }

    // Check if the page has permission key and if the user has that permission
    else if (pagePermissionKey && !checkAuth(authList, pagePermissionKey)) {
        return <Navigate to="/sign-in" replace />;
    }

    return <>
        {authList?.length > 0 && <Navbar />}
        {children}
    </>;
};