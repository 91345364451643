import { License } from "src/types/organization";
import { deleteAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "./helpers/axiosHelper";

export const createLicense = async (license: License) => {
    return postAxiosWithToken({ url: '/licences?is_debug=true', body: license }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const updateLicense = async (license: License) => {
    return putAxiosWithToken({ url: `/licences/${license._id}?is_debug=true`, body: license }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const deleteLicense = async (license_id: string) => {
    return deleteAxiosWithToken({ url: `/licences/${license_id}?is_debug=true` }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}