import { postAxiosWithToken } from "./helpers/axiosHelper"

export const authorizeUser = async (args : {
    user_id: string,
    role_id: string,
    service_id: string,
}) => {
    return postAxiosWithToken({ url: `/auth/authorize`, body: args }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}