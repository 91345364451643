import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface FormAutoCompleteProps {
    name: string;
    control: any;
    label: string;
    defaultValue?: string;
    options: { label: string; value: string }[];
}

export const FormAutoComplete = ({ name, control, label, defaultValue, options }: FormAutoCompleteProps) => {

    const generateSelectOptions = () => {
        return options.map((option) => {
            return {
                label: option.label,
                value: option.value
            }
        });
    };

    return <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    onChange={(event, newValue) => {
                        onChange(newValue.value);
                    }}
                    value={value}
                    options={generateSelectOptions()}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </FormControl>
        )}
    />
};