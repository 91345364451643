import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useAuth } from "qreal-auth-provider/src";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { changePassword, changePasswordWithToken } from "src/services/userService";
import { UserServices } from "src/types/user";
import { FormInputText } from "./formElements/formInputText";
import SubmitButton from "./formElements/formSubmitButton";
import ResponseModule from "./shared/reponseNotification";

interface ChangePasswordFormProps {
    id: string
}

const ChangePasswordForm = ({ id }: ChangePasswordFormProps) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<any>({
        message: '',
        error: true,
    });

    return <Grid container justifyContent='center'>
        <Grid item xs={12} md={6} sx={{ border: '1px solid #80808033' }}>
            <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    Change Password
                </Typography>
                <Divider sx={{ mb: 4 }} />
                <form onSubmit={handleSubmit((data) => {
                    setLoading(true);
                    setResponse({ message: '', error: true });
                    if (data.newPassword !== data.passwordConfirm) {
                        setResponse({ message: 'Passwords do not match', error: true });
                        setLoading(false);
                        return;
                    }
                    changePasswordWithToken(id, data.newPassword).then((response) => {
                        console.log('test', response);
                        if(response.data && response.data.msg) {
                            setResponse({ message: response.data.msg, error: 'Password Changed Successfully' !== response.data.msg });
                        } else {
                            setResponse({ message: 'Something went wrong', error: true });
                        }
                        setLoading(false);
                    });


                })}>
                    <Grid container spacing={3} justifyContent='space-between'>
                        <Grid item xs={12} alignItems='center'>
                            <FormInputText
                                label='New Password'
                                name='newPassword'
                                register={register}
                                type="password"
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} alignItems='center'>
                            <FormInputText
                                label='Password Confirm'
                                name='passwordConfirm'
                                register={register}
                                type="password"
                                errors={errors}
                            />
                        </Grid>
                        <Grid item container xs={12} justifyContent='center'>
                            <SubmitButton
                                loading={loading}
                            > Change Password </SubmitButton>
                        </Grid>
                    </Grid>
                </form>
                {response.message && <ResponseModule
                    message={response.message}
                    error={response.error}
                />}

            </Stack>
        </Grid>
    </Grid>
}

export default ChangePasswordForm;