import { useParams } from "react-router-dom";
import ForgotPasswordForm from "src/components/forgotPasswordForm";
import PublicView from "src/components/layouts/publicView";

const UserForgotPassword = () => {

    return (
        <PublicView>
            <ForgotPasswordForm />
        </PublicView>
    )
}
export default UserForgotPassword;