import axios from "axios";

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_API;

const errorResponse = (error: any): { error: boolean, errorMessage: string, data: null } => {
    return {
        error: true,
        errorMessage: error?.response?.data?.message[0]?.error_code || "Something went wrong",
        data: null,
    };
};

export const post = async (args: { url: string; body: any; config?: any; }) => {
    try {
        return await axios.post(args.url, args.body, args.config)
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getAxiosWithToken = async (args: { url: string; config?: any; }) => {
    try {
        return await axios.get(AUTH_BASE_URL + args.url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const postAxiosWithToken = async (args: { url: string; body: any; config?: any; }) => {
    try {
        return await axios.post(AUTH_BASE_URL + args.url, args.body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return error.response
    }
}

export const deleteAxiosWithToken = async (args: { url: string; config?: any; }) => {
    try {
        return await axios.delete(AUTH_BASE_URL + args.url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const putAxiosWithToken = async (args: { url: string; body: any; config?: any; }) => {
    try {
        return await axios.put(AUTH_BASE_URL + args.url, args.body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
            ...args.config,
        })
    } catch (error: any) {
        return errorResponse(error)
    }
}

export const getFileSize = async (url: string) => {
    return await axios.get(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        method: 'HEAD',
    }).then((res) => {
            return res.headers['content-length'];
        }
    )
}