import { Role } from 'src/types/role';
import { getAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "./helpers/axiosHelper"

export const getAllRoles = async () => {
    return getAxiosWithToken({ url: '/roles?list_all=true' }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const getRolesWithPagination = async (page: number, per_page: number) => {
    return getAxiosWithToken({ url: `/roles?page=${page}&per_page=${per_page}` }).then((response: any) => {
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const createRole = async (role: {
    role_name: string,
    role_description: string,
}) => {
    return postAxiosWithToken({ url: '/roles?is_debug=true', body: role }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            const err = response.data.errors[0]
            throw err.msg;
        }
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}


export const updateRolePermissions = async (role: Role) => {
    return putAxiosWithToken({
        url: `/roles/${role._id}?is_debug=true`, body: {
            permissions: role.permissions
        }
    }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            const err = response.data.errors[0]
            throw err.msg;
        }
        return response.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}