import axios from "axios";

export const searchLog = async (datum: {
    log_group_name: string,
    query_string: string,
    region: string,
    start_date: string,
    end_date: string,
}) => {
    return await axios.post(process.env.REACT_APP_LOG_API + '/logs/search?is_debug=true', datum, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
    }).then((response: any) => {
        if (response.data && !response.data.is_complete) {
            throw 'Something went wrong';
        }
        return response.data.message
    }).catch((error: any) => {
        throw new Error(error)
    })
}