import { Organization } from './../types/organization';
import { deleteAxiosWithToken, getAxiosWithToken, postAxiosWithToken, putAxiosWithToken } from "./helpers/axiosHelper"

export const getAllOrganizations = async () => {
    return getAxiosWithToken({ url: '/organizations?list_all=true&is_debug=true' }).then((response: any) => {
        console.log('hello', response)
        if (response.data && response.data.is_complete) {
            return response.data.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const lisOrganizationWithPagination = async (page: number, per_page: number) => {
    return getAxiosWithToken({ url: `/organizations?page=${page}&per_page=${per_page}` }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const createOrganization = async (organization: {
    organization_name: string,
    organization_description: string,
}) => {
    return postAxiosWithToken({ url: '/organizations?is_debug=true', body: organization }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const deleteOrganization = async (organization_id: string) => {
    return deleteAxiosWithToken({ url: `/organizations/${organization_id}?is_debug=true` }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data
        } else {
            throw new Error('Error in fetching data')
        }

    }
    ).catch((error: any) => {
        throw new Error(error)
    })
}

export const updateOrganization = async (organization: {
    _id: string,
    organization_status: string,
    organization_description: string,
}) => {

    console.log('test', organization);
    return putAxiosWithToken({
        url: `/organizations/${organization._id}`, body: {
            organization_description: organization.organization_description,
            organization_status: organization.organization_status
        }
    }).then((response: any) => {
        return response.data.data
    }).catch((error: any) => {
        throw new Error(error)
    })
}

export const getOrganizationLicenses = async (organization_id: string) => {
    return getAxiosWithToken({ url: `/licences/get-by-organization/${organization_id}?is_debug=true` }).then((response: any) => {
        if (response.data && response.data.is_complete) {
            return response.data.data
        } else {
            throw new Error('Error in fetching data')
        }

    }).catch((error: any) => {
        throw new Error(error)
    })
}