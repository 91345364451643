import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface FormInputDropdownProps {
    name: string;
    control: any;
    label: string;
    defaultValue?: string;
    options: { label: string; value: string }[];
}

export const FormInputDropdown = ({ name, control, label, defaultValue, options }: FormInputDropdownProps) => {

    const generateSelectOptions = () => {
        return options.map((option) => {
            return (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            );
        });
    };

    return <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
                <InputLabel id={`dropdown-id-${name}`}>{label}</InputLabel>
                <Select labelId={`dropdown-id-${name}`} onChange={onChange} value={value} label={label}>
                    {generateSelectOptions()}
                </Select>
            </FormControl>
        )}
    />
};